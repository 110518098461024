import { RightOutlined } from '@ant-design/icons';
import { Col, Grid, List, Row, Typography } from 'antd';
import { graphql, Link, PageProps } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import React from 'react';

import Container from '@/components/Container';
// import GroupCards from '@/components/GroupCards';
import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import { useGroupedProduction } from '@/hooks/useGroupedProduction';
import { getCurrentBreakpoint } from '@/utils/mappings';

// TODO: Migrate it
import * as styles from './category.module.css';

const CatalogPage: React.FC<
  PageProps & {
    data: {
      file: ImageDataLike;
    };
    pageContext: {
      categoryName: string;
    };
  }
> = ({ uri, data: { file: categoryImage }, pageContext: { categoryName } }) => {
  const groupedProductsByCategory = useGroupedProduction();
  const categoryGroups = groupedProductsByCategory[categoryName].products;
  const screens = Grid.useBreakpoint();
  const currentBreakpoint = getCurrentBreakpoint(screens);
  const maxTextLength = Math.max(
    ...categoryGroups.map(({ groupProducts }) =>
      groupProducts.reduce(
        (memo, i) => Math.max(memo, i.name.type.length, i.name.option.length),
        0
      )
    )
  );
  const categoryRows = categoryGroups.reduce(
    (memo, i) => memo + i.groupProducts.length,
    categoryGroups.length
  );
  const shouldBeCollapsed = categoryRows > 9;
  const image = getImage(categoryImage);

  return (
    <Layout>
      <SEO
        title={categoryName}
        description={categoryGroups
          .map((i) =>
            `${i.groupProducts.map(
              (j) => `${j.name.type} ${j.name.option}`
            )}`.trim()
          )
          .join(', ')}
        uri={uri}
        image={null}
      />
      <Typography.Title className="text-center mt-6 mb-8">
        <Link to="/catalog">
          <Typography.Text type="secondary">Каталог</Typography.Text>
        </Link>
        {' / '}
        {categoryName}
      </Typography.Title>
      <Container>
        {/* {categoryGroups.map((i) => (
          <GroupCards groupName={i.groupTitle} key={i.groupTitle} />
        ))} */}
        <Row
          align="middle"
          wrap={['xs', 'sm'].includes(currentBreakpoint)}
          style={{
            background: '#fff',
          }}
          className="rounded-2xl pr-4 py-4"
        >
          <Col
            flex={
              ['xs', 'sm'].includes(currentBreakpoint)
                ? '100%'
                : `${maxTextLength * 9 + 48 + 16}px`
            }
          >
            <List
              size={shouldBeCollapsed ? 'small' : 'large'}
              className={styles.CategoryList}
              dataSource={categoryGroups}
              renderItem={({ groupTitle, groupProducts: groupItems }) =>
                groupItems.map((p, idx) =>
                  !shouldBeCollapsed ? (
                    <Link to={`/catalog/${p.slug}`}>
                      <List.Item>
                        <List.Item.Meta
                          title={groupTitle}
                          description={p.name.option}
                        />
                        <RightOutlined />
                      </List.Item>
                    </Link>
                  ) : idx === 0 ? (
                    <>
                      <List.Item>
                        <List.Item.Meta title={groupTitle} />
                      </List.Item>
                      <List.Item>
                        <Link className="ml-4" to={`/catalog/${p.slug}`}>
                          {p.name.option}
                        </Link>
                      </List.Item>
                    </>
                  ) : (
                    <List.Item>
                      <Link className="ml-4" to={`/catalog/${p.slug}`}>
                        {p.name.option}
                      </Link>
                    </List.Item>
                  )
                )
              }
            />
          </Col>
          <Col
            flex={['xs', 'sm'].includes(currentBreakpoint) ? '100%' : 'auto'}
            style={{
              order: ['xs', 'sm'].includes(currentBreakpoint) ? -1 : undefined,
            }}
          >
            <GatsbyImage
              style={{ maxHeight: 600, float: 'right' }}
              imgStyle={{
                objectFit: 'contain',
                objectPosition: 'center right',
              }}
              image={image as any}
              alt={categoryName}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default CatalogPage;

export const pageQuery = graphql`
  query CategoryPhoto($categoryPhotoBase: String) {
    file(
      sourceInstanceName: { eq: "categories" }
      base: { eq: $categoryPhotoBase }
    ) {
      id
      childImageSharp {
        gatsbyImageData(height: 600)
      }
    }
  }
`;
